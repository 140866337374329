import React from "react";
import MasterLayout from "../layouts/master";
import { Text, Heading, Container, Box, chakra } from "@chakra-ui/react";

const PrivacyPolicyPage = (props) => {
  return (
    <MasterLayout>
      <Container maxW={"6xl"} py={12}>
        <Heading
          lineHeight={1.1}
          fontWeight={"bold"}
          py={6}
          fontSize={{ base: "xl", sm: "xl", lg: "6xl" }}
        >
          <Text
            as={"span"}
            position={"relative"}
            _after={{
              content: "''",
              width: "full",
              height: "30%",
              position: "absolute",
              bottom: 1,
              left: 0,
              bg: "#8fe7a3",
              zIndex: -1,
            }}
          >
            Privacy Policy
          </Text>
        </Heading>
        <Text>
          We value your trust. In order to honor that trust, Suno Kitaab adheres
          to ethical standards in gathering, using, and safeguarding any
          information you provide. Suno Kitaab Private Limited (operating under
          the brand name Suno Kitaab), is a leading edtech company, incorporated
          in India, for imparting learning. This privacy policy governs your use
          of the application 'Suno Kitaab App' ('Application'),
          www.sunokitaab.com ('Website'), and the other associated applications,
          products, websites, and services managed by the Company. Please read
          this privacy policy ('Policy') carefully before using the Application,
          Website, our services, and products, along with the Terms of Use
          ('ToU') provided on the Application and the Website. Your use of the
          Website, Application, or services in connection with the Application,
          Website or products ('Services'), or registrations with us through any
          modes or usage of any products including through SD cards, tablets, or
          other storage/transmitting device shall signify your acceptance of
          this Policy and your agreement to be legally bound by the same. If you
          do not agree with the terms of this Policy, do not use the Website,
          Application our products, or avail of any of our Services.
        </Text>
        <Box>
          <chakra.h4 fontSize={"1.2rem"} fontWeight={"bold"} py={4}>
            Our Family Policy
          </chakra.h4>
          <Text>
            Our app's content is accessible to children
            and appropriate for children
          </Text>
        </Box>
        <Box>
          <chakra.h4 fontSize={"1.2rem"} fontWeight={"bold"} py={4}>
            App Functionality
          </chakra.h4>
          Your app does not provide a webview of a website or have a primary
          purpose of driving affiliate traffic to a website, regardless of
          ownership of the website liked to Sunokitaab
        </Box>
        <Box>
          Your app does not provide a web-view of a website or have a primary
          purpose of driving affiliate traffic to a website, regardless of
          ownership of the website liked to Sunokitaab. 1. Our app's users can
          not interact or exchange information; 2. Our app does not share
          user-provided information with third parties; 3. Our app does not
          share the user’s physical location with other users.
          <Text fontWeight={"bold"} py={2}>
            User-Provided Information
          </Text>
          The Application/Website/Services/products obtain the information you
          provide when you download and register for the Application or Services
          or products. When you register with us, you generally provide (a) your
          name, age, phone number, state, district and school name; (b)
          transaction-related information, such as when you make purchases,
          respond to any offers, or download or use applications from us; (c)
          information you provide us when you contact us for help; (d)
          information you enter into our system when using the
          Application/Services/products, such as registration. The said
          information collected from the users could be categorised as “Personal
          Information”, “Sensitive Personal Information” and “Associated
          Information”. Personal Information, Sensitive Personal Information and
          Associated Information (each as individually defined under this
          Information Technology (Reasonable security practices and procedures
          and sensitive personal data or information) Rules, 2011 (the “Data
          Protection Rules”)) shall collectively be referred to as 'Information'
          in this Policy. We may use the Information to contact you from time to
          time, to provide you with the Services, important information,
          required notices, and marketing promotions. We will ask you when we
          need more information that personally identifies you (personal
          information) or allows us to contact you. We will not differentiate
          between who is using the device to access the Application, Website or
          Services, or products, so long as the log-in/access credentials match
          with yours. In order to make the best use of the
          Application/Website/Services/products and enable your Information to
          be captured accurately on the Application/Website/Services/products,
          you must be logged in using your credentials.
          <Text fontWeight={"bold"} py={2}>
            Automatically Collected Information
          </Text>
          In addition, the Application/products/Services may collect certain
          information automatically, including, but not limited to, the type of
          mobile device you use, your mobile device unique device ID, the IP
          address of your mobile device, your mobile operating system, the type
          of mobile Internet browsers you use, and information about the way you
          use the Application/Services/products. As is true of most Mobile
          applications, we also collect other relevant information as per the
          permissions that you provide. We use an outside credit card processing
          company to bill you for goods and services. These companies do not
          retain, share, store or use personally identifiable information for
          any other purpose.
          <Text fontWeight={"bold"} py={2}>
            Use of your Personal Information
          </Text>
          We use the collected information to analyze trends, conduct research,
          administer the Application/Services and products, learn about each
          user's learning patterns and movements around the Application/Services
          and products and gather demographic information and usage behavior
          about our user base as a whole. Aggregated and individual, anonymized,
          and non-anonymized data may periodically be transmitted to external
          service providers to help us improve the Application, products, and
          our Services. We will share your information with third parties only
          in the ways that are described below in this Policy. We may use the
          individual data and behavior patterns combined with personal
          information to provide you with personalized content and better your
          learning objectives. Third parties provide certain services which we
          may use to analyze the data and information to personalize, drive
          insights and help us better your experience or reach out to you with
          more value-added applications, products, information, and services.
          However, these third-party companies do not have any independent right
          to share this information. We do not sell, trade, or share your
          information to any third party (except subsidiaries/affiliates of the
          company for related offerings) unless we have been expressly
          authorized by you either in writing or electronically to do so. We may
          at times provide aggregate statistics about our customers, traffic
          patterns, and related site information to reputable third parties,
          however, information this information when disclosed will be in an
          aggregate form and does not contain any of your Personally
          Identifiable Information. Suno Kitaab will occasionally send email
          notices, messages, or contact you to communicate about our Services,
          products, and benefits, as they are considered an essential part of
          the Services/products you have chosen. We may disclose Information: -
          as required by law, such as to comply with a subpoena or similar legal
          process; - to enforce applicable ToU, including investigation of
          potential violations thereof; - when we believe in good faith that
          disclosure is necessary to protect our rights, protect your safety or
          the safety of others, investigate fraud, address security or technical
          issues or respond to a government request; - with our trusted services
          providers who work on our behalf, do not have an independent use of
          the information we disclose to them and have agreed to adhere to the
          rules outlined in this Policy; - to protect against imminent harm to
          the rights, property, or safety of the Application/Website/ Suno
          Kitaab Private Limited or its users or the public as required or
          permitted by law; - with third-party service providers in order to
          personalize the Application/Website/Services/products for a better
          user experience and to perform behavioral analysis; - Any portion of
          the Information containing personal data relating to minors provided
          by you shall be deemed to be given with the consent of the minor's
          legal guardian. Such consent is deemed to be provided by your
          registration with us.
          <Text fontWeight={"bold"} py={2}>
            Access to your Personal Information
          </Text>
          We will provide you with the means to ensure that your Personal
          Information is correct and current. If you have filled out a user
          profile, we will provide an obvious way for you to access and change
          your profile from our Application/Services/Website/products. We adopt
          reasonable security measures to protect your password from being
          exposed or disclosed to anyone.
          {/* <Text fontWeight={"bold"} py={2}>
          Cookies
          </Text>
          We send cookies (small files
          containing a string of characters) to your computer, thereby uniquely
          identifying your browser. Cookies are used to track your preferences,
          help you log in faster, and aggregate to determine user trends. This
          data is used to improve our offerings, such as providing more content
          in areas of greater interest to a majority of users. Most browsers are
          initially set up to accept cookies, but you can reset your browser to
          refuse all cookies or to indicate when a cookie is being sent. Some of
          our features and services may not function properly if your cookies
          are disabled. */}
          <Text fontWeight={"bold"} py={2}>
            Links
          </Text>
          We may present links in a format that enables us to keep track of
          whether these links have been followed. We use this information to
          improve our customized content. Clicking on links may take you to
          sites outside our domain. We are not responsible for the privacy
          practices of other websites. We encourage our users to be aware when
          they leave our site to read the privacy statements of each website
          that collects personally identifiable information. This Privacy Policy
          applies solely to information collected by our Website.
          <Text fontWeight={"bold"} py={2}>
            Alerts
          </Text>
          We may alert you by email or phone or whatsapp (through SMS/call) to inform you
          about new service offerings of the Company or occasionally send you messages to wish you and its
          subsidiaries/affiliates or other information which we feel might be
          useful for you, through the company or its subsidiaries/affiliates.
          <Text fontWeight={"bold"} py={2}>
            Public Forums
          </Text>
          When you use certain features on our website like the discussion
          forums and you post or share your personal information such as
          comments, messages, files, photos, will be available to all users, and
          will be in the public domain. All such sharing of information is done
          at your own risk. Please keep in mind that if you disclose personal
          information in your profile or when posting on our forums this
          information may become publicly available.
          <Text fontWeight={"bold"} py={2}>
            Security
          </Text>
          We are concerned about safeguarding the confidentiality of your
          Information. We provide physical, electronic, and procedural
          safeguards to protect the Information we process and maintain. For
          example, we limit access to this information to authorized employees
          only who need to know that information in order to operate, develop or
          improve our Application/Services/products/Website. Please be aware
          that, although we endeavor to provide reasonable security for
          information we process and maintain, no security system can prevent
          all potential security breaches.
          <Text fontWeight={"bold"} py={2}>
            How Long Do We Retain User Data?
          </Text>
          Currently, we plan to retain user data while an account is active and
          for at least three years afterward. We may alter this practice
          according to legal and business requirements. For example, we may
          lengthen the retention period for some data if needed to comply with
          law or voluntary codes of conduct. Unless otherwise prohibited, we may
          shorten the retention period for some types of data if needed to free
          up storage space.
          <Text fontWeight={"bold"} py={2}>
            Log information
          </Text>
          When you access our Website, our servers automatically record
          information that your browser sends whenever you visit a website.
          These server logs may include information such as your web request,
          internet protocol address, browser type, browser language, the date
          and time of your request, and one or more cookies that may uniquely
          identify your browser.
          <Text fontWeight={"bold"} py={2}>
            User communications
          </Text>
          When you send an email or other communication to us, we may retain
          those communications in order to process your inquiries, respond to
          your requests and improve our Services.
          <Text fontWeight={"bold"} py={2}>
            Changes to this Statement
          </Text>
          As the Company evolves, our privacy policy will need to evolve as well
          to cover new situations. You are advised to review this Policy
          regularly for any changes, as continued use is deemed approval of all
          changes.
          <Text fontWeight={"bold"} py={2}>
            Your Consent
          </Text>
          We believe that every user of our
          Application/Services/products/Website must be in a position to provide
          informed consent before providing any Information required for the use
          of the Application/Services/products/Website. By registering with us,
          you are expressly consenting to our collection, processing, storing,
          disclosing, and handling of your information as outlined in this
          Policy now and as amended by us. Processing, your information in any
          way, including, but not limited to, collecting, storing, deleting,
          using, combining, sharing, transferring, and disclosing information,
          all of which activities will take place in India. If you reside
          outside India your information will be transferred, processed, and
          stored in accordance with the applicable data protection laws of
          India.
          <Text fontWeight={"bold"} py={2}>
            Contact Information
          </Text>
          Our Grievance Officer shall undertake all reasonable efforts to
          address your grievances at the earliest possible opportunity. You may
          contact us at Grievance Officer: Mr. Ajay 307, Supreme Cowork DLF
          Mall, Shalimar Bagh, Delhi, 110088, India. Reach out to us at
          info@sunokitaab.com, in case of any queries.
        </Box>
        <Box>
          <Text fontWeight={"bold"} py={2}>Data Collection and Use</Text>

          In order to provide and improve our Services, SunoKitaab collects certain types of information from our users and upload file information. This section describes the types of data we collect, how we use it, and your rights regarding this data.

          <Text fontWeight={"bold"} py={2}>Information We Collect
          </Text>
          When you use our app, we may collect the following types of information: {`\n`}

          1. Personal Information: We may collect personal data such as your name and phone number. This information is required to create and manage your account within the app.{`\n`}

          2. App Usage Information: We may collect information about how you use our app, including crash data and diagnostic data. This information helps us understand how our app is being used and allows us to identify and fix any issues that may arise.

          <Text fontWeight={"bold"} py={2}>How We Use Your Information</Text>

          1. To provide and manage your account: We use your personal information to create and manage your account within our app. This includes communicating with you about your account and providing support when needed.{`\n`}

          2. For analytics and app improvement: We use crash data and diagnostic data to analyze and improve our app's performance, functionality, and user experience. This information helps us identify any problems or areas that need improvement so we can continue to make our app better for our users.

          <Text fontWeight={"bold"} py={2}>Your Rights and Choices</Text>

          As a user of our app, you have the right to request access to, update or delete your personal information at any time. To request data deletion, please visit <a href="https://sunokitaab.com/request-data-deletion">here</a>. To request account deletion, please visit <a href="https://sunokitaab.com/request-account-deletion">here</a>.

          If you have any questions or concerns about our data collection practices, please contact us using the contact information provided in our Privacy Policy.
        </Box>
      </Container>
    </MasterLayout>
  );
};

export default PrivacyPolicyPage;
